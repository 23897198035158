import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const MarigalantePirateShip = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'Marigalante Pirate Ship | HB Privileged',
      title: 'MARIGALANTE PIRATE SHIP',
      subtitle: 'TOUR OVERVIEW',
      text: 'A classic in Puerto Vallarta! This impressive historic galeon sails 2 times a day. In the daytime it´s great to take the children snorkeling, kayaking or paddle boarding to Mahauitas Beach. It includes breakfast, lunch and full open bar. It is fun for all ages!! At night the adults sail along our bay to our famous Malecon. See all of Puerto Vallarta hotels from the ocean. Admire our beautiful sunset, have a majestic lobster dinner and enjoy the pirate show. All of these surrounded by the crew perfoming live music and serving you the best drinks during the international open bar. Sail away! Ahoy!',
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'Barco Pirata Marigalante | HB Privileged',
      title: 'BARCO PIRATA MARIGALANTE',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Un clásico en Puerto Vallarta! Este impresionante galeón histórico navega 2 veces al día. Por la mañana es genial para llevar a los niños a snorkelear, hacer kayac, o tabla de remo en la playa Mahauitas. Incluye desayuno, comida y barra libre. Es divertido para todas las edades! En la noche, los adultos navegan hacia nuestro famoso malecón. Observa toda la zona hotelera de Vallarta desde el océano. Admira nuestro bellísimo atardecer, ten una majestuosa cena de langosta y disfruta el show pirata. Todos esto rodeado de la tripulación tocando música en vivo y sirviéndote las mejores bebidas durante la barra libre internacional. A navegar! Ahoy!',
      frequency: 'Frequencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/marigalante-pirate-ship/marigalante1.png`,
    `${BASE_URL}/marigalante-pirate-ship/marigalante2.png`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/marigalante-pirate-ship/marigalante-banner.png`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default MarigalantePirateShip;